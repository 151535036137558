<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="title">Research Library</h2></template>
      </card-title>
    </template>
    <template #content-full>
      <div class="flex flex-column">
        <h3 class="mb-3">Introduction</h3>
        <div class="flex align-items-center justify-content-between">
          <div class="mr-5">
            <div class="card-body">The research library provides access to research notes that cover a range of financial topics. Search for research notes by category or use the search box to search for a key term.</div>
          </div>
          <div class="button-cta">
            <Button class="clarity-btn clarity-gradient-grey" :href="prefix+'/toolsvideos'" target="_blank">Videos & Webinars</Button>
          </div>
        </div>
      </div>

      <spacer-h />

      <div v-if="loadingArticles">
        <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
        <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
        <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
        <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
      </div>

      <div class="article-search-filter" v-if="!loadingArticles">
        <div class="article-search">
          <span class="p-input-icon-left cl-input full-width">
            <i class="pi pi-search"></i>
            <InputText type="text"  v-model="filters.global.value" placeholder="Looking for a Research Note..." class="p-inputtext-lg w-full" />
          </span>
        </div>
        <div class="article-filter">
          <div class="dropdown">
            <label>Filters:</label>
            <MultiSelect v-model="filters.category.value" :options="options" placeholder="Select category to filter">
            <template #value="slotProps">
              {{ getValue(slotProps) }}
            </template>
          </MultiSelect>
          </div>
        </div>
      </div>
      <DataTable
          v-if="articles && !loadingArticles"
          :value="articles"
          class="rd-table"
          rowGroupMode="subheader"
          groupRowsBy="category"
          sortField="category"
          sortMode="single" :sortOrder="1"
          scrollable
          scrollHeight="1000px"
          id="articles"
          :globalFilterFields="['category', 'name']"
          v-model:filters="filters"
      >
        <Column field="name" header="Research Note" sortable style="min-width: 60%;">
          <template #body="slotProps">
            <div class="flex align-items-center">
              <a :href="getClarityLink(slotProps.data.url)" target="_blank"><h3 class="light-blue mr-3 pt-2">{{slotProps.data.name}}</h3></a>
              <Pill v-if="checkArticleDate(slotProps.data)" :label="checkArticleDate(slotProps.data)" :colour="checkArticleDate(slotProps.data) === 'New Document' ? 'clarity-gradient-blue' : 'clarity-gradient-light-blue'" />
            </div>
          </template>
        </Column>
        <Column field="actions" header="Actions" style="min-width: 25%;">
          <template #body="slotProps">
            <div class="actions">
              <div class="doc-link"><a :href="getClarityLink(slotProps.data.url)" target="_blank"><div v-tooltip.top="'Download PDF file'"><Icon name="pdf-icon" /></div></a></div>
                  <!-- @TODO: Yet to be implemented - pinned for v2 -->
              <!--  <div v-tooltip.top="'Link to the Website'" class="doc-link" @click="copyToClipboardAsync(slotProps.data.url)" :id="slotProps.data.url"><span class="material-icons-round grey">insert_link</span></div>-->
              <!--  <div v-tooltip.top="'Email Me Document'" class="doc-link"><a :href="getMailToLink()" target="_blank"><span class="material-icons-round grey">email</span></a></div>-->
              <!--  <div v-tooltip.top="'Add to my Reading list'" class="doc-link" @click="addToReadingList()"><span class="material-icons-round grey">add</span></div>-->
              <!--  <div v-tooltip.top="{value: 'Remove from my Reading list', class: 'center-tooltip-text'}" class="doc-link" @click="removeFromReadingList()"><span class="material-icons-round grey">remove</span></div>-->
            </div>
          </template>
        </Column>
        <template #groupheader="slotProps">
          <div :id="`rowGroupHeader-${slotProps.data.category}`" class="subheader" v-html="slotProps.data.category"></div>
        </template>
        <template #footer>
        </template>
        <template #empty>
          <div>No Articles to display</div>
        </template>
      </DataTable>
    </template>
  </content-card-full>
</template>

<script>
import api from "@/core/services/api";
import dayjs from "dayjs";
import {FilterMatchMode} from "primevue/api";
import {onMounted, ref } from "vue";
import {toDrupalRequest} from "@/core/helpers";
import {useStore} from "vuex";
import CardTitle from '@/components/common/CardTitle.vue'
import ContentCardFull from '@/components/common/ContentCardFull.vue'
import Icon from '@/components/common/Icon'
import Pill from "@/components/nav/Pill";
import SpacerH from '@/components/common/layout/SpacerH.vue'

export default {
  name: "TabResearchLibrary",
  setup() {
    onMounted(() => {
      getData()
    })

    const store = useStore()
    const articles = ref([])
    const loadingArticles = ref(true)
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      'category': {value: null, matchMode: FilterMatchMode.IN},
    })
    const options = ref()
    let prefix = ref(process.env.VUE_APP_CLARITYURL)

    const getData = async () => {
      const endpoint = `/researchlibrary`
      const response = await api.get(`${toDrupalRequest(endpoint)}`)
      if(response && response.error) {
        await store.dispatch('addNotification', {severity: 'error', summary: 'Get Research Library Articles', detail: `'Unable to get research library`})
      }
      if (response) {
        articles.value = response.researchlibrary
        buildFilterOptions()
      }
      loadingArticles.value = false
    }

    const buildFilterOptions = () => {
      const opts = []
      if(articles.value && articles.value.length) {
        articles.value.forEach(a => {
            opts.push(a.category)
        });
      }

      options.value = opts.length ? [...new Set(opts)] : []
    }

    const getValue = (slotProps) => {
      switch (true){
        case slotProps.value && slotProps.value.length  === options.value.length:
          return 'All'
        case slotProps.value && slotProps.value.length > 2:
          return 'Multiple'
        case slotProps.value && slotProps.value.length > 0:
          return slotProps.value.join(", ")
        default:
          return 'Select category to filter'
      }
    }

    const checkArticleDate = (article) => {
      const today = dayjs()
      const diff = today.diff(article.date_added, 'months')
      const updated = dayjs(article.last_updated) > dayjs(article.date_added)
      if (diff <= 3) {
        return 'New Document'
      }
      if (updated) {
        return 'Recently Updated'
      }
    }

    const copyToClipboardAsync = str => {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        store.dispatch('addNotification',{severity: 'success', summary: 'Link copied to clipboard', detail: ``, life: 4000})
        return navigator.clipboard.writeText(prefix.value+str);
      }
      return Promise.reject('The Clipboard API is not available.');
    };

    const getClarityLink = (url) => {
      if(url.includes('http')) {
        return url;
      } else {
        return prefix.value+url;
      }
    }

    // @TODO: Yet to be implemented - pinned for v2
    // const getMailToLink = () => {
    //   console.log('Email yet to be implemented')
    // }
    //
    // const addToReadingList = () => {
    //   console.log('Reading List and ToDo list yet to be implemented')
    // }
    //
    // const removeFromReadingList = () => {
    //   console.log('Reading List and ToDo list yet to be implemented')
    // }

    return {
      filters,
      loadingArticles,
      articles,
      options,
      getValue,
      checkArticleDate,
      copyToClipboardAsync,
      getClarityLink,
      prefix
      // getMailToLink,
      // addToReadingList,
      // removeFromReadingList
    }
  },
  components: {
    CardTitle,
    ContentCardFull,
    Icon,
    Pill,
    SpacerH
  }
}
</script>

<style scoped>
.card-body {
  font-size: 18px;
  padding: 5px 0px;
}
.button-cta {
  display: flex;
  justify-content: center;
}
.button-cta button {
  padding: 15px 45px;
  margin: 20px;
  width: max-content;
}
/* carried over from buylist */
img {
  opacity: 1;
}
.rd-table:deep(.p-rowgroup-header):first-of-type,
.rd-table:deep(.p-rowgroup-header){
  font-weight: bold;
  background: transparent linear-gradient(180deg, var(--clarity-snow-grey) 0%, var(--clarity-light-grey) 100%) 0% 0% no-repeat padding-box;
}
.rd-table:deep(.p-rowgroup-header){
  border-top: 2px solid var(--clarity-pure-white);
}
.rd-table {
  font-size: 18px;
}
.rd-table:deep(.p-datatable-footer){
  border-bottom-left-radius: 27px;
  border-bottom-right-radius: 27px;
  border-bottom: none;
}
.selected-option {
  font-weight: bold;
}
.doc-links {
  display: flex;
}
.doc-link {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  color: var(--clarity-grey);
  margin: 0px 10px;
}
.doc-link {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: var(--clarity-dark-grey);
}
.doc-link:hover {
    color: var(--clarity-blue);
    cursor: pointer;
}
.doc-link img {
    filter: invert(13%) sepia(3%) saturate(2094%) hue-rotate(171deg) brightness(98%) contrast(90%);
}
.doc-link:hover img {
    filter: invert(15%) sepia(78%) saturate(4473%) hue-rotate(198deg) brightness(95%) contrast(101%);
}
.actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.actions button {
  padding: 20px 45px;
}
.article-search-filter {
  display: flex;
  width: 100%;
}
.article-search,
.article-filter {
  display: flex;
  width: 30%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 30px
}
.article-filter label {
  font-size: 18px;
  margin-right: 20px;
}
.article-filter .p-multiselect,
.dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;
}
</style>
